import React, { useEffect, useRef, useState } from "react";
import {
    Editor,
    EditorState,
    RichUtils,
    convertFromRaw,
    convertToRaw,
} from "draft-js";
import Toolbar from "./Toolbar/Toolbar";
import "./DraftEditor.css";
import converter from 'draft-js-bbcode-converter';

const DraftEditor = (props) => {

    const { value, onChange } = props;
    const editor = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [editorState, setEditorState] = useState(
        EditorState.createEmpty()
    );

    useEffect(() => {
        if (!loaded && value) {
            try {
                const contentState = converter.bbcodeImport(value);
                setEditorState(EditorState.createWithContent(contentState));
            } catch(err) {
                console.log(err)
            }
            setLoaded(true);
        }
    }, [value, loaded]);

    useEffect(() => {
        focusEditor();
    }, []);

    const focusEditor = () => {
        editor.current.focus();
    };

    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return true;
        }
        return false;
    };

    // FOR INLINE STYLES
    const styleMap = {
    };

    // FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
    const myBlockStyleFn = (contentBlock) => {
        const type = contentBlock.getType();
        switch (type) {
        case "blockQuote":
            return "superFancyBlockquote";
        default:
            break;
        }
    };

    return (
        <div className="editor-wrapper" onClick={focusEditor}>
            <Toolbar editorState={editorState} setEditorState={setEditorState} />
            <div className="editor-container">
                <Editor
                    ref={editor}
                    placeholder="Write Here"
                    handleKeyCommand={handleKeyCommand}
                    editorState={editorState}
                    customStyleMap={styleMap}
                    blockStyleFn={myBlockStyleFn}
                    onChange={(editorState) => {
                        const contentState = editorState.getCurrentContent();
                        const bbcodeString = converter.bbcodeExport(contentState);
                
                        if (value !== bbcodeString && loaded) {
                            if (onChange) {
                                onChange(bbcodeString)
                            }
                            setEditorState(editorState);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default DraftEditor;


/**
 * @param { EditorState } editorState
 * @returns { object }
 */
export const toRaw = editorState => {
    return convertToRaw(editorState.getCurrentContent());
};
  
/**
 * @param { EditorState } editorState
 * @param { object } raw
 * @returns { EditorState }
 */
export const fromRaw = (editorState, raw) => {
    return EditorState.push(editorState, convertFromRaw(raw), 'update-state');
};
