/* @flow */

// Framework
import React from 'react'
import { Form, Col, InputGroup, Button } from 'react-bootstrap'
import { NewTextEditor, LoadedTextEditor } from '../ControlledEditor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NEW = 'NewTextEditor'
export const LOAD = 'LoadedTextEditor'

export const renderFormEditor = (props) => {
    const {
        name,
        disabled,
        placeholder,
        input,
        namePrefix,
        showLeftLabel,
        label,
        parts,
        showLabel,
        size,
        icon,
        submitStyle,
        submitIcon,
        appendSubmit,
        editorType, // "NewTextEditor" or "LoadedTextEditor"
        input: { onBlur, onChange, value },
        // meta: { touched, error, warning }
    } = props

    return (
        <Form.Group as={Col} xs={parts} controlId={`${(namePrefix ? namePrefix : '')}${input.name}`}>
            {showLabel && <Form.Label style={{fontSize: '12px', marginBottom: '2px'}}>{label}</Form.Label>}
            <InputGroup size={size} style={{display: 'block'}}>
            {showLeftLabel && <Form.Label column sm={2}>{label}</Form.Label>}
            {icon && <InputGroup.Prepend>
                <InputGroup.Text id={`${input.name}Prepend`}>
                <FontAwesomeIcon icon={icon} />
                </InputGroup.Text>
            </InputGroup.Prepend>}
            {(editorType === NEW) && <NewTextEditor
                {...input}
                key={name}
                name={name}
                id={name}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value} />}
            {(editorType === LOAD) && <LoadedTextEditor
                {...input}
                key={name}
                name={name}
                id={name}
                disabled={disabled}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                value={value} />}
            {appendSubmit && <InputGroup.Append>
                <Button variant="dark" type="submit" style={submitStyle}><FontAwesomeIcon icon={submitIcon} /></Button>
            </InputGroup.Append>}
                {/* {touched &&
                ((error && <span>{error}</span>) ||
                (warning && <span>{warning}</span>))} */}
            </InputGroup>
        </Form.Group>
    );
}
